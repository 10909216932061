export default {
    "slo": {
        izpolnipolja: "Izpolni vsa polja",
        vpisiuporabniskoime: "Vpiši uporabniško ime!",
        prikaznijezik: "SI",
        imeigre: "DESETNIK",
        opisigre: "Premešaj dokler vsa števila na kockah niso enaka. Pritisni na eno od kock, da jo zamrzneš pri premešanju.",
        ui: "Uporabniško ime:",
        naprimer: "Npr. Krokar",
        zapomni: "Zapomni si podatke.",
        igraj: "Igraj",
        uporabnik: "Uporabnik:",
        novaigra: "Nova igra",
        premesaj: "Premešaj",
        premesav: "premešav",
        cas: "Čas",
        najboljsicas: "Najboljši čas",
        sekund: "sekund",
        nidosezeno: "Ni še dosežen",
        zmaga: "Čestitke, uspelo ti je!",
        obvestilonajcas: "Dosegel si najboljši čas!",
        dobrodosel: "Dobrodošel",
        nasvidenje: "Nasvidenje"

    },
    "nem": {
        izpolnipolja: "Sie müssen alle Felder ausfüllen!",
        vpisiuporabniskoime: "Geben Sie Ihren Benutzernamen ein!",
        prikaznijezik: "DE",
        imeigre: "DIE ZEHNER",
        opisigre: "Rollen Sie, bis alle Würfel gleich sind. Klicken Sie auf jeden Würfel, um ihn zwischen den Würfen auf seinem aktuellen Wert einzufrieren.",
        ui: "Benutzername:",
        naprimer: "Zum Beispiel: Ulrich",
        zapomni: "Merken Sie sich den Namen und die Sprache",
        igraj: "Spielen Sie",
        uporabnik: "Benutzer:",
        novaigra: "Neues Spiel",
        premesaj: "Neu würfeln",
        premesav: "Mischung",
        cas: "Zeit",
        najboljsicas: "Die beste Zeit",
        sekund: "Sekund",
        nidosezeno: "Noch nicht erreicht!",
        zmaga: "Gut gemacht, du hast es geschafft!",
        obvestilonajcas: "Du hast deinen Rekord gebrochen!",
        dobrodosel: "Herzlich willkommen",
        nasvidenje: "Auf Wiedersehen"
    }
}